import React from "react";
import closedEventIcon from "../../resources/closedEventIcon.png";
import ongoingIcon from "../../resources/ongoingInvestigationIcon.png";
import closedOutbreakIcon from "../../resources/closedOutbreakIcon.png";
import moment from "moment";
import { fontSize } from "../../constants/styled";

const getTextIconMappings = (eventData) => {
    switch (eventData.outbreak_status) {
        case "Closed":
            return {
                text1: `DSO ${eventData.district} (${eventData.state})`,
                text2: `closed an event Published on ${moment(eventData.publish_date).format("Do MMM 'YY")}`,
                icon: closedEventIcon
            }
        case "Ongoing":
            return {
                text1: `DSO ${eventData.district} (${eventData.state})`,
                text2: `started investigation for alert Published on ${moment(eventData.publish_date).format("Do MMM 'YY")}`,
                icon: ongoingIcon
            }
        case "Completed":
            return {
                text1: `DSO ${eventData.district} (${eventData.state})`,
                text2: `closed an outbreak for alert Published on ${moment(eventData.publish_date).format("Do MMM 'YY")}`,
                icon: closedOutbreakIcon
            }
        default:
            return { text1: "", text2: "", icon: null }
    }
}

const Event = ({ eventData, authToken, setShowSummary = () => { }, eventAlertChecked = () => { } }) => {
    const handleClick = () => {
        setShowSummary({ show: true, eventId: eventData?.id, outbreakStatus: eventData?.outbreak_status })
        eventAlertChecked([eventData?.id], authToken)
    }
    return (
        <div className={`py-3 border-bottom-1 border-[#D8DBD4] px-4 hover:cursor-pointer`} onClick={handleClick}>
            <div className="flex w-full justify-between space-x-2">
                <div className="w-1/12">
                    <img src={getTextIconMappings(eventData)?.icon} alt="status-icon" className="h-[1.8rem] md:h-[2rem] lg:h-[2.2rem] pt-1" />
                </div>
                <div className="w-7/12">
                    <p className={`${fontSize[14]} font-[700] text-[#5E6060]`}>{getTextIconMappings(eventData)?.text1} <span className="font-[500]">{getTextIconMappings(eventData)?.text2}</span></p>
                    <p className={`${fontSize[10]} font-[400] text-[#ACADAD]`}>2hrs ago</p>
                </div>
                <div className="w-4/12 flex justify-end items-end">
                    <div className="bg-[#E6F1F6] group-hover:bg-[#5CAFD8] rounded-[4px] px-2 py-1">
                        <p className={`${fontSize[14]} text-blue group-hover:text-white font-[500]`}>{eventData.disease}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Event;
