import React from "react";
import { fontSize } from "../../constants/styled";
import bellIcon from "../../resources/bellIcon.png";
import bellIconHighlighted from "../../resources/bellIconHighlighted.png";
import Event from "./Event";
import SummaryDialog from "../Dialogs/SummaryDialog/SummaryDialog";
import { eventAlertChecked, getAlertUpdates } from "../../utils/apiCalls";
import { routes } from "../../constants/routings";
import useReduxSearch from "../../hooks/useReduxSearch";
import EventSkeleton from "./EventSkeleton";

const Updates = () => {
    const menuRef = React.useRef(null);
    const [showUpdates, setShowUpdates] = React.useState(false)
    const [showSummary, setShowSummary] = React.useState({ show: false, eventId: null, outbreakStatus: null })
    const [alertUpdates, setAlertUpdates] = React.useState({ isFetching: true, updates: null })
    const authToken = useReduxSearch(routes.auth.key).authToken
    const handleUpdatesButtonClick = async () => {
        if (!showUpdates) {
            setAlertUpdates((prev) => ({ ...prev, isFetching: true }))
            handleGetUpdatesApiCall()
        }
        setShowUpdates(!showUpdates)
    }
    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowUpdates(false)
        }
    }
    const handleClickOnSummaryDialog = () => {
        setShowSummary((prev) => ({ ...prev, show: false, outbreakStatus: null }))
        setTimeout(() => {
            setAlertUpdates((prev) => ({ ...prev, updates: prev.updates.filter((update) => update.id !== showSummary.eventId) }))
            setShowSummary((prev) => ({ ...prev, eventId: null }))
        }, 3000)
    }
    const handleGetUpdatesApiCall = async () => {
        const updates = await getAlertUpdates(authToken)
        setAlertUpdates((prev) => ({ isFetching: false, updates }))
    }
    const handleClearAllUpdates = async () => {
        const ids = alertUpdates.updates.map(obj => obj.id)
        const resp = await eventAlertChecked(ids, authToken)
        if (resp) {
            setAlertUpdates({ isFetching: false, updates: [] })
            setTimeout(() => {
                setShowUpdates(false)
            }, 2000)
        }
    }
    React.useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    });
    return (
        <div className="h-full">
            <div className="h-full relative"
                // ref={menuRef}
            >
                <div className={`flex items-center h-full space-x-2 rounded-[8px] border-1 px-2 ${showUpdates ? "bg-[#D4EAF0] border-[#D4EAF0]" : "border-[#C7C3C3] hover:hover:bg-blue-light"} hover:cursor-pointer`} onClick={handleUpdatesButtonClick}>
                    <div className={`relative`}>
                    <img src={showUpdates ? bellIconHighlighted : bellIcon} alt="bell-icon" className="h-[1.1rem] md:h-[1.3rem] lg: h-[1.5rem]" />
                    {alertUpdates?.updates?.length > 0 && (
                        <div className="absolute top-0 right-[2px] rounded-full bg-[#EC4646] w-[6px] h-[6px]"></div>
                        )}
                    </div>
                    <p className={`${fontSize[14]} font-[500] ${showUpdates ? "text-[#008BCA]" : "text-text-color"}`}>Updates</p>
                </div>
                {showUpdates && <div className="absolute w-[30rem] h-[31.5rem] flex flex-col bg-white right-0 mt-1 z-1 shadow-[0px_4px_4px_0px_#00000040] rounded-[12px] pt-2">
                    {alertUpdates.isFetching && <EventSkeleton />}
                    {!alertUpdates.isFetching && alertUpdates.updates.length > 0 && <div className="bg-white pb-2 z-1">
                        <p className="text-blue px-4 text-end">{alertUpdates.updates.length} Alert{alertUpdates.updates.length > 1 && "s"}</p>
                    </div>}
                    {!alertUpdates.isFetching && <div className="overflow-auto mt-[-15px]">
                        {alertUpdates.updates.length > 0 && alertUpdates.updates.map((event) => <div className={`${event?.id === showSummary?.eventId && "bg-[#EDF6F9]"} hover:bg-[#E6F1F6] group`}>
                            <Event key={event.id} eventData={event} authToken={authToken} setShowSummary={setShowSummary} eventAlertChecked={eventAlertChecked}/>
                        </div>)}
                    </div>}
                    <div className="flex justify-end mr-4 py-2">
                        {!alertUpdates.isFetching && alertUpdates.updates.length > 0 && <div className="hover:cursor-pointer px-2 py-1 bg-[#E8F0F3] rounded-[5px]" onClick={handleClearAllUpdates}>
                            <p>Clear all</p>
                        </div>}
                    </div>
                </div>}
            </div>
            {showSummary.show && <SummaryDialog showDialog={showSummary.show} eventId={showSummary.eventId} eventStatus={showSummary.outbreakStatus} setShowDialog={handleClickOnSummaryDialog} />}
        </div>
    )
}

export default Updates;
