import React from "react";
import CustomButton from "../../shared/CustomButton/CustomButton";
import { fontSize } from "../../../constants/styled";
import CustomGroupMultiSelect from "../../shared/CustomGroupMultiSelect/CustomGroupMultiSelect";
import diseaseIcon from "../../../resources/diseaseIcon.png";
import diseaseHighlightedIcon from "../../../resources/diseaseHighlightedIcon.png";
import locationIcon from "../../../resources/locationIcon.png";
import locationHighlightedIcon from "../../../resources/locationHighlightedIcon.png";
import useReduxSearch from "../../../hooks/useReduxSearch";
import { routes } from "../../../constants/routings";
import { filterActions } from "../../../store/filterSlices";
import { useDispatch } from "react-redux";
import CustomMultiSelect from "../../shared/CustomMultiSelect/CustomMultiSelect";
import { postSaveAllFilterAction } from "../../../store/eventActions";
import CustomTimePicker from "../../shared/CustomTimePicker/CustomTimePicker";
import CustomDatePicker from "../../shared/CustomDatePicker/CustomDatePicker";
import { getToday, getYesterDay } from "../../../utils/methods";

const otherDistrictOption = {
    label: "Other",
    items: [{
        name: "No District",
        value: "",
    }],
};

const getDistrictCategories = (selectedStates, districts) => {
    const filteredDistricts = districts.filter(category =>
        selectedStates.some(state => category.label === state)
    );
    return [otherDistrictOption, ...filteredDistricts]
}

const eventTypes = [
    routes.extracted.eventType,
    routes.rejected.eventType,
    routes.shortlisted.eventType,
    routes.healthInfo.eventType,
    routes.published.eventType,
]
const FilterDropdown = ({ selectedFilterData, filterFields, changedFields, setChangedFields = () => { }, closeFilter = () => { } }) => {
    const dispatch = useDispatch()
    const authToken = useReduxSearch(routes.auth.key).authToken;
    const filterData = useReduxSearch(routes.filters.key);
    const [selectedStateCategories, setSelectedStateCategories] = React.useState(selectedFilterData.state);
    const [districtCategories, setDistrictCategories] = React.useState(getDistrictCategories(selectedFilterData.state, [otherDistrictOption, ...filterData.district]));
    const [selectedDistrictCategories, setSelectedDistrictCategories] = React.useState(selectedFilterData.district);
    const [selectedDiseaseCategories, setSelectedDiseaseCategories] = React.useState(selectedFilterData.disease);
    const [fromDate, setFromDate] = React.useState(selectedFilterData.fromDate)
    const [toDate, setToDate] = React.useState(selectedFilterData.toDate)
    const handleDiseaseSelect = (event) => {
        setSelectedDiseaseCategories(event.target.value)
        setChangedFields((prev) => ({ ...prev, fields: { ...prev.fields, disease: true } }))
    }
    const handleStateSelect = (event) => {
        const selectedStates = event.target.value
        setSelectedStateCategories(selectedStates)
        const districts = getDistrictCategories(selectedStates, filterData.district);
        setDistrictCategories([].concat(districts))
        const selectedDistricts = districts.flatMap(state => state.items).map(dist => dist.value)
        setSelectedDistrictCategories(selectedDistricts)
        setChangedFields((prev) => ({ ...prev, fields: { ...prev.fields, state: true } }))
    }
    const handleDistrictSelect = (event) => {
        setSelectedDistrictCategories(event.target.value)
        setChangedFields((prev) => ({ ...prev, fields: { ...prev.fields, district: true } }))
    }
    const handleDateChange = (date, type) => {
        switch (type) {
            case "fromDate":
                setFromDate(date)
                setChangedFields((prev) => ({ ...prev, fields: { ...prev.fields, fromDate: true } }))
                break
            case "fromTime":
                setFromDate(date)
                setChangedFields((prev) => ({ ...prev, fields: { ...prev.fields, fromTime: true } }))
                break
            case "toDate":
                setToDate(date)
                setChangedFields((prev) => ({ ...prev, fields: { ...prev.fields, toDate: true } }))
                break
            case "toTime":
                setToDate(date)
                setChangedFields((prev) => ({ ...prev, fields: { ...prev.fields, toTime: true } }))
                break
            default:
                break
        }
    };
    const handleSaveAllFilter = () => {
        const params = {
            eventType: selectedFilterData.eventType,
            fromDate,
            toDate,
            disease: selectedDiseaseCategories,
            state: selectedStateCategories,
            district: selectedDistrictCategories,
            currentPage: 1,
        }
        if (selectedFilterData.subTypes && selectedFilterData.subTypes.length) {
            params["subTypes"] = selectedFilterData.subTypes
        }
        dispatch(postSaveAllFilterAction(params, eventTypes, authToken))
        dispatch(filterActions.setSelectedFilters({
            fromDate,
            toDate,
            disease: selectedDiseaseCategories,
            state: selectedStateCategories,
            district: selectedDistrictCategories,
        }))
        const isAnyFieldChanged = Object.values(changedFields.fields).every(value => value === false)
        setChangedFields((prev) => ({ isChanged: !isAnyFieldChanged, fields: { ...prev.fields } }))
        closeFilter()
    }
    const handleClearFilter = () => {
        const newFromDate = getYesterDay()
        const newToDate = getToday();
        const newselectedDiseases = filterData.disease.flatMap(disease => disease?.items).map(disease => disease?.value).filter(dis => dis !== "COVID-19");
        const newSelectedStates = ["", ...(filterData.state.map(st => st?.value))]
        const newDistricts = getDistrictCategories(newSelectedStates, filterData.district);
        const newSelectedDistricts = newDistricts.flatMap(state => state.items).map(dist => dist.value)
        const params = {
            eventType: selectedFilterData.eventType,
            fromDate: newFromDate,
            toDate: newToDate,
            disease: newselectedDiseases,
            state: newSelectedStates,
            district: newSelectedDistricts,
            currentPage: 1,
        }
        if (selectedFilterData.subTypes && selectedFilterData.subTypes.length) {
            params["subTypes"] = selectedFilterData.subTypes
        }
        dispatch(postSaveAllFilterAction(params, eventTypes, authToken))
        dispatch(filterActions.setSelectedFilters({
            fromDate: newFromDate,
            toDate: newToDate,
            disease: newselectedDiseases,
            state: newSelectedStates,
            district: newSelectedDistricts,
        }))
        setFromDate(fromDate)
        setToDate(toDate)
        setSelectedDiseaseCategories(newselectedDiseases)
        setSelectedStateCategories(newSelectedStates)
        setSelectedDistrictCategories(newSelectedDistricts)
        setChangedFields({
            isChanged: false,
            fields: filterFields.reduce((acc, field) => {
                acc[field] = false;
                return acc;
            }, {})
        })
        closeFilter()
    }
    return <>
        <div className="rounded-[5px] w-[100%] bg-[#ECF8FB] p-4 absolute z-3 shadow-2xl filter-dropdown">
            <div className="w-full space-y-4 lg:space-y-5 xl:space-y-6">
                <div className="flex w-full">
                    <div className="w-1/4 md:w-1/5 lg:w-1/6">
                        <p className={`${fontSize[14]} text-text-color font-[700]`}>From</p>
                    </div>
                    <div className="w-3/4 md:w-3/5 lg: w-3/6 relative">
                        <div className="flex w-full">
                            <div className={`w-1/2 mr-2 ${changedFields.fields.fromDate && "text-blue"}`}>
                                <CustomDatePicker date={fromDate} maxDate={toDate} type={"fromDate"} highlight={changedFields.fields.fromDate} setDate={handleDateChange} />
                            </div>
                            <div className={`w-1/3 ml-2 ${changedFields.fields.fromTime && "text-blue"}`}>
                                <CustomTimePicker date={fromDate} maxDate={toDate} type={"fromTime"} iconClass={`pi-clock ${changedFields.fields.fromTime ? "text-blue" : "text-text-color"}`} setDate={handleDateChange}/>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="w-1/4 md:w-1/5 lg:w-1/6">
                        <p className={`${fontSize[14]} text-text-color font-[700]`}>To</p>
                    </div>
                    <div className="flex space-x-2 md:space-x-3 lg:space-x-4 w-3/4 md:w-3/5 lg: w-3/6 relative">
                        <div className={`w-1/2 ${changedFields.fields.toDate && "text-blue"}`}>
                            <CustomDatePicker date={toDate} minDate={fromDate} maxDate={new Date()} highlight={changedFields.fields.toDate} type={"toDate"} setDate={handleDateChange} />
                        </div>
                        <div className={`w-1/3 ${changedFields.fields.toTime && "text-blue"}`}>
                            <CustomTimePicker date={toDate} minDate={fromDate} type={"toTime"} iconClass={`pi-clock ${changedFields.fields.toTime === true ? "text-blue" : "text-text-color"}`} setDate={handleDateChange}/>
                        </div>
                    </div>
                </div>
                <div className="flex">
                    <div className="w-1/4 md:w-1/5 lg:w-1/6">
                        <p className={`${fontSize[14]} text-text-color font-[700]`}>Disease Type</p>
                    </div>
                    <div className="flex space-x-[2px] w-4/4 lg:w-9/12 2xl:w-8/12 relative">
                        <div className={`w-1/2 relative ${changedFields.fields.disease && "text-blue"}`}>
                            <CustomGroupMultiSelect placeholder={"Select Disease"} filter={true} icon={changedFields.fields.disease ? diseaseHighlightedIcon : diseaseIcon} options={filterData.disease} selectedOptions={selectedDiseaseCategories} highlight={changedFields.fields.disease} selectAllPlaceholder={"Diseases"} classes="w-full rounded-[8px]" handleSelection={handleDiseaseSelect} />
                        </div>
                        <div className="w-1/2 relative">
                        </div>
                    </div>
                </div>
                <div className="flex items-center">
                    <div className="w-1/4 md:w-1/5 lg:w-1/6">
                        <p className={`${fontSize[14]} text-text-color font-[700]`}>Location</p>
                    </div>
                    <div className="flex space-x-[2px] w-4/4 lg:w-9/12 2xl:w-8/12">
                        <div className={`w-1/2 relative ${changedFields.fields.state && "text-blue"}`}>
                            <CustomMultiSelect placeholder={"Select State"} filter={true} icon={changedFields.fields.state ? locationHighlightedIcon : locationIcon} options={ [{ name: "Other", value: "" }, ...filterData.state] } selectedOptions={selectedStateCategories} highlight={changedFields.fields.state} selectAllPlaceholder={"States"} classes="w-full rounded-s-[8px]" handleSelection={handleStateSelect} />
                        </div>
                        <div className={`w-1/2 relative ${changedFields.fields.district && "text-blue"}`}>
                            <CustomGroupMultiSelect placeholder={"Select District"} filter={true} options={districtCategories} selectedOptions={selectedDistrictCategories} selectAllPlaceholder={"Districts"} classes="w-full rounded-e-[8px]" handleSelection={handleDistrictSelect} />
                        </div>
                    </div>
                </div>
                <div>
                    <div className="flex mt-[3rem] justify-end items-center space-x-5 lg:space-x-7">
                        <div className="">
                            <CustomButton
                                fontSize={fontSize[14]}
                                text={{ text: "Reset", class: "text-text-color font-[500]" }}
                                hover="hover:cursor-pointer"
                                action={handleClearFilter}
                            />
                        </div>
                        <div>
                            <CustomButton
                                fontSize={fontSize[14]}
                                text={{ text: "Save all filter results", class: "text-white font-[500]" }}
                                background="px-2 md:px-3 lg:px-4 py-2 lg:py-3  whitespace-nowrap bg-green"
                                border="rounded-[20px]"
                                hover="hover:cursor-pointer"
                                action={handleSaveAllFilter}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
};

export default FilterDropdown;
